import Routeing from "./Screen/Routing/routing";

function App() {
  return (
    <div className="">
      <Routeing />
    </div>
  );
}

export default App;
